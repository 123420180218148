import axios from "axios"

var done = false
var permessi: null | any[] = null

export const getPermessi = async () => {
    let response: boolean = false
    await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/roles`, {
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": `Bearer ${window.localStorage.getItem('token')}`,
        }
    }).then((res: any) => {
        permessi = res.data.data
        response = true
    }).catch((err: any) => {
        response = false
    })

    return response
}

// export const getPermessi = async () => {
//     let response = false;
//     console.log("PRE FETCH PERMESSI");
//
//     try {
//         const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/roles`, {
//             method: 'GET',
//             headers: {
//                 "Content-Type": "application/json; charset=UTF-8",
//                 "Authorization": `Bearer ${window.localStorage.getItem('token')}`,
//             }
//         });
//
//         if (!res.ok) {
//             throw new Error('Network response was not ok');
//         }
//
//         const data = await res.json();
//         console.log("RISPOSTA PERMESSI", data.data);
//         permessi = data.data;
//         response = true;
//     } catch (err) {
//         console.error('Errore:', err);
//         response = false;
//     }
//
//     return response;
// }

export function checkRoles(role: number, translate: any) {
    let text = ''

    if (role < 20) {
        text = translate('super-dio', 'roles')
    } else if (role > 19 && role < 30) {
        text = translate("amministratore", 'roles')
    } else if (role > 29 && role < 40) {
        text = translate("responsabile", 'roles')
    } else if (role > 39 && role < 50) {
        text = translate("qualificatore", 'roles')
    } else if (role > 49 && role < 60) {
        text = translate("visualizzatore", 'roles')
    } else if (role > 59 && role < 70) {
        text = translate("valutatore", 'roles')
    } else if (role >= 70) {
        text = translate("fornitore", 'roles')
    }

    return text
}

export async function checkPermissions(permissions: string[], role: number) {
    let result: any = {
        view: false,
        update: false,
        create: false,
        delete: false,
        fornitore: {
            view: false,
            update: false,
            create: false,
            delete: false,
        },
        vendorRating: {
            view: false,
            update: false,
            create: false,
            delete: false,
        },
        generic: {
            view: false,
            update: false,
            create: false,
            delete: false,
        },
        roles: {
            view: false,
            update: false,
            create: false,
            delete: false,
        },
        user: {
            view: false,
            update: false,
            create: false,
            delete: false,
        },
    }

    permessi = permissions

    // if(role < 70){
    //     if (!permessi && !done) {
    //         await getPermessi().then((res: any) => {
    //             done = true
    //         }).catch((err: any) => {
    //             result = false
    //         })
    //     }
    // }else{
    //     permessi = permissions
    // }


    if (permessi) {
        for (let item of permissions) {
            let split = item.split('_')

            if (item.includes("everything") || role < 10) {
                result[split[0]] = true
            } else {
                result[split[1]][split[0]] = true
            }
        }

    }


    return {permessi_roles: result, permissions: permessi}
}

export function checkAction(permissions: any,key: string, action: string): boolean {

    if(permissions.create || permissions.delete || permissions.update || permissions.view){
       return true
    }else{
        return permissions[key][action]
    }
}
