'use client'

// React Imports
import {ReactNode, useEffect, useState} from 'react'

// Third-party Imports
import classnames from 'classnames'

// Type Imports
import type {ChildrenType} from '@core/types'

// Component Imports
import LayoutContent from './components/vertical/LayoutContent'

// Util Imports
import {verticalLayoutClasses} from './utils/layoutClasses'

// Styled Component Imports
import StyledContentWrapper from './styles/vertical/StyledContentWrapper'
import {getDictionary} from "@/utils/getDictionary";
import type {Locale} from "@configs/i18n";
import Fade from "@mui/material/Fade";
import {useSessionContext} from "@layouts/SessionContext";

export type VerticalLayoutProps = ChildrenType & {
    navigation?: ReactNode
    navbar?: ReactNode
    footer?: ReactNode,
}

const VerticalLayout = (props: VerticalLayoutProps) => {
    const sessionContext: any = useSessionContext();
    // Props
    const {navbar, footer, navigation, children} = props

    return sessionContext && sessionContext?.user ? (
            <Fade in timeout={300}>
                <div className={classnames(verticalLayoutClasses.root, 'flex flex-auto')}>
                    {navigation || null}
                    <div className={'flex'} style={{width: '100%'}}>
                        {/*<div style={{width: '17%'}} className="background-svg-dati min-bs-[100dvh] relative max-md:hidden"></div>*/}

                        <div
                            className="lg:min-w-[13%]"
                            style={{
                                position: "relative",
                                height: "100%",/* o qualsiasi altezza desiderata */
                                overflow: "hidden" /* Permette lo scrolling se necessario */
                            }}
                        >
                            <div
                                //  style={{width: '17%'}}
                                className="background-svg-dati lg:min-w-[350px]"></div>
                        </div>


                        <div style={{width: '100%'}}
                             className={classnames(verticalLayoutClasses.contentWrapper, 'flex flex-col !min-is-full lg:!min-is-[unset] is-full')}>

                            {navbar || null}
                            {/* Content */}
                            <LayoutContent>{children}</LayoutContent>
                            {footer || null}
                        </div>
                    </div>
                </div>
            </Fade>

        ) : null
}

export default VerticalLayout
